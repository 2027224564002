import { useState } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import {
  MDBNavbarBrand,
  MDBCollapse,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBContainer,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavbarLink,
  MDBCol,
  MDBFooter,
  MDBIcon,
  MDBRow,
    MDBBtn,
    MDBCard,
    MDBCardFooter,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCarousel,
    MDBCarouselItem,
    MDBCarouselCaption,
} from 'mdb-react-ui-kit';
import './App.css';

function App() {
  const [openBasic, setOpenBasic] = useState(false);
  return (
    <MDBContainer fluid>
      <header>
        {/* Main Navigation */}
        <MDBNavbar expand='lg' className='fixed-top'>
          {/* Container wrapper */}
          <MDBContainer sm>

            <MDBNavbarBrand href='#'>
              AVTiB
            </MDBNavbarBrand>

                        <MDBCollapse navbar open={openBasic}>
                            <MDBNavbarNav right className='mb-2 mb-lg-0'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink>
                                        <nav id="applications">
                                            <NavLink to='/applications'>
                                                Product Overview
                                            </NavLink>
                                        </nav>
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink>
                                        <nav id="home">
                                            <NavLink to='https://docs.avtib.ae/' target="_blank" rel="noopener noreferrer">
                                                Documentation
                                            </NavLink>
                                        </nav>
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                AI Features
                                            </MDBDropdownToggle>
                                        </nav>
                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem link>AI Forecasting Engine</MDBDropdownItem>
                                                <MDBDropdownItem link>AI Recommendation Engine</MDBDropdownItem>
                                                <MDBDropdownItem link>AI Classification</MDBDropdownItem>
                                                <MDBDropdownItem link>AI Detection</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                Pricing
                                            </MDBDropdownToggle>
                                        </nav>
                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem link>Pricing Guide</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                About Us
                                            </MDBDropdownToggle>
                                        </nav>
                                        
                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem link>About Us</MDBDropdownItem>
                                                <MDBDropdownItem link>Contact Us</MDBDropdownItem>
                                                <MDBDropdownItem link>FAQ</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>

                        <MDBNavbarNav className='d-flex flex-row' right fullWidth={false}>
                            {/* <Notifications />
                            <LanguageSelect />
                            <Avatar /> */}
                            <MDBBtn tag="a" outline size="lg">
                                <NavLink to='#' target="_blank" rel="noopener noreferrer">
                                    Client Portal
                                </NavLink>
                            </MDBBtn>
                        </MDBNavbarNav>
          </MDBContainer>
        </MDBNavbar>


      </header>


      <div
        className='p-5 text-center bg-image'
        style={{ backgroundImage: "url('/images/header.jpg')", height: '700px' }}
      >
         <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>Welcome to AVTiB</h1>
                            <h4 className='mb-3'>Train.Infer.Build</h4>
                        </div>
                    </div>
                </div>
      </div>

      <MDBFooter className='text-center text-lg-start text-muted'>
               

                <section className=''>
                    <MDBContainer className='text-center text-md-start mt-5'>
                        <MDBRow className='mt-3'>
                            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>
                                    <MDBIcon icon="gem" className="me-3" />
                                    AVTiB
                                </h6>
                                <p>
                                    AVTiB, is an AI API For Training, Inferencing and Building AI Applications.
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Features</h6>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Data Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Video Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Image Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Audio Analytics
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>AI Features</h6>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Forecasting & Prediction
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Recommendation Engine
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Classification
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Detection
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="2" lg="2" xl="3" className='mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Contact</h6>
                                <p>
                                    <MDBIcon icon="home" className="me-3" />Dubai , United Arab Emirates
                                </p>
                                <p>
                                    <MDBIcon icon="envelope" className="me-3" />register@avtib.ae
                                </p>
                                <p>
                                    <MDBIcon icon="phone" className="me-3" />+971 58 552 2636
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                    <div className='me-5 d-none d-lg-block'>
                        <span className="header-2">Get connected with us on social networks:</span>
                    </div>

                    <div className="header-2">
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="facebook-f" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="twitter" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="google" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="instagram" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="linkedin" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="github" />
                        </a>
                    </div>
                </section>
                <div className='text-center p-4 header-2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © 2024 Copyright:
                    <a className='text-reset fw-bold' href='http://www.avisionsoftware.com/'>
                        AVTiB.com
                    </a>
                </div>
            </MDBFooter>
    </MDBContainer>

  );
}

export default App;
